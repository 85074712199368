import { createSlice } from "@reduxjs/toolkit";
import { getAuthProps, setAuthProps, signOut } from "../../lib/authenticationLibrary";
import { saveData } from '../../utils/LocalStorage/LocalStorageManager';

const authData = getAuthProps();

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: (authData != null),
        user: (authData?.user), //Set user data from cookie.
        token: (authData?.token), //Set token data from cookie.
        authData,
        navigationMenuList: [],
        isLogedin: false,
        isPasswordResetRequired: false
    },
    reducers: {
        authentication: (state, action) => {

            const { securityResponses, ...newAuthProps } = action.payload;
            setAuthProps(newAuthProps);

            const { isAuthenticated, message, token, user, sessionTimeout, ...navigationMenuItems } = action.payload;

            state.navigationMenuList = navigationMenuItems.securityResponses;
            saveData('NavigationMenu', navigationMenuItems.securityResponses);

            state.isLogedin = true;
            state.isPasswordResetRequired = action.payload.user.bPasswordResetRequired  ? action.payload.user.bPasswordResetRequired  : false
        },
        logout: (state) => {
            state.isLogedin = false;
            signOut();
        },
    },
});

export const { authentication, logout } = authSlice.actions;

export default authSlice.reducer;
import React from 'react';
import validator from 'validator';

export const required = (value) => value !== undefined && value !== null && String(value).trim() !== '';

export const email = (value) => value === undefined || value === null || validator.isEmail(String(value));

export const number = (value) => value === 0 || !isNaN(value);

export const maxLength = (value, maxLen) => String(value).trim().length <= maxLen;

export const minLength = (value, minLen) => String(value).trim().length >= minLen;

export const compare = (value, compareValue) => value === compareValue;

export const distinct = (value, compareValue) => value !== '' && value !== compareValue;

export const maxProspects = (value, maxProspectsValue) => value <= maxProspectsValue;

export const minEndDate = (date, minEndDateValue) => date >= minEndDateValue;

export const maxSum = (sum, maxSumValue) => sum <= maxSumValue;

export const password = (value, props, components) => value === components['confirm'][0].value ? undefined : <span className="error">Passwords are not equal.</span>;

export const uniqueIdentifier = (value) => !!(value && String(value).trim().length && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value));

// export const isvalidPassword = (value) => !!(value && String(value).trim().length && /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{10,})/.test(value));
// export const isvalidPassword = (value) => !!(value && String(value).trim().length && /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*\d){1,})(?=(.*[!@#$%^&*]){1,}).{8,}$/.test(value));

export const isvalidPassword = (value) => {
    if (!value || !String(value).trim().length || value.length < 8) return false;
    let fulfilledCategories = 0;
    if (/[a-z]/.test(value)) fulfilledCategories++;
    if (/[A-Z]/.test(value)) fulfilledCategories++;
    if (/\d/.test(value)) fulfilledCategories++;
    if (/[!@#$%^&*]/.test(value)) fulfilledCategories++;
    return fulfilledCategories >= 3;
}

export const isValidEIN = (value) => {
    const unmaskedValue = value.replace(/\D/g, '')
    const isValid = /^\d{9}$/.test(unmaskedValue);
    return isValid;
};

export const isValidPhone = (value) => {
    const unmaskedValue = value.replace(/\D/g, '')
    const isValid = /^\d{10,15}$/.test(unmaskedValue);
    return isValid;
}

export const isValidFax = (value) => {
    const unmaskedValue = value.replace(/\D/g, '')
    const isValid = /^\d{10}$/.test(unmaskedValue);
    return isValid;
}

// Get the file extension
const getFileExtension = (fileName) => {
    return fileName?.slice(fileName.lastIndexOf('.')).toLowerCase(); // Extracts the extension with the dot
};

export const validateFile = (file, acceptedFormats, minSizeMB = 0, maxSizeMB = Infinity) => {
    const validationState = {
        isValid: true,
        errorMessage: ``
    }

    if (!file) {
        // Return consistent object structure
        validationState.isValid = false;
        validationState.errorMessage = "No file selected.";
        return validationState;
    }

    // Check file format
    const fileExtension = getFileExtension(file.name);
    if (acceptedFormats?.includes('*') || acceptedFormats?.includes(fileExtension)) {
        // If "ANY" is included or the file extension is valid, set isValid to true
        validationState.isValid = true;
    } else {
        // Otherwise, mark it as invalid and show an error message
        validationState.isValid = false;
        validationState.errorMessage = `Invalid file format. Accepted formats: ${acceptedFormats.join(', ')}.`;
    }

    // Check file size
    const fileSizeMB = file.size / 1024 / 1024; // Convert bytes to MB

    if (fileSizeMB > maxSizeMB) {

        validationState.isValid = false;
        validationState.errorMessage = `File size exceeds the maximum limit of ${maxSizeMB} MB.`;
    }
    if (fileSizeMB < minSizeMB) {

        validationState.isValid = false;
        validationState.errorMessage = `File size must be at least ${minSizeMB} MB.`;
    }

    return validationState; // File is valid
};


/** Updated version for Multi File validation */
export const validateFile2 = (files, acceptedFormats, minSizeMB = 0, maxSizeMB = Infinity) => {

    const validationResults = [];

    const fileArray = Array.isArray(files) ? files : [files];

    fileArray.forEach(file => {
        const validationState = {
            fileName: file?.name || null,
            isValid: true,
            message: "",
        };

        if (!file) {
            validationState.isValid = false;
            validationState.message = "No file selected.";
        } else {
            // Check file format
            const fileExtension = getFileExtension(file.name);
            if (
                acceptedFormats.includes("*") ||
                acceptedFormats.includes(fileExtension)
            ) {
                validationState.isValid = true;
            } else {
                validationState.isValid = false;
                validationState.message = `Invalid file format. Accepted formats: ${acceptedFormats.join(", ")}.`;
            }

            // Check file size
            const fileSizeMB = file.size / 1024 / 1024; // Convert bytes to MB

            if (fileSizeMB > maxSizeMB) {
                validationState.isValid = false;
                validationState.message = `File size exceeds the maximum limit of ${maxSizeMB} MB.`;
            } else if (fileSizeMB < minSizeMB) {
                validationState.isValid = false;
                validationState.message = `File size must be at least ${minSizeMB} MB.`;
            }
        }

        validationResults.push(validationState);
    });

    return validationResults;
};


export const validateImageFile = (file, fieldSettings, imageData) => {

    const {
        acceptedImageFormats,
        maxImageHeight,
        maxImageWidth,
        minImageHeight,
        minImageWidth,
        maxImageSizeInMB,
        minImageSizeInMB,
    } = fieldSettings;

    const validationState = {
        isValid: true,
        errorMessage: ``
    }

    if (!file) {
        validationState.isValid = false;
        validationState.errorMessage = "No file selected.";
        return validationState;
    }

    // Check file format
    const fileExtension = getFileExtension(file.name);
    if (acceptedImageFormats?.includes('*') || acceptedImageFormats?.includes(fileExtension)) {
        validationState.isValid = true;
    } else {
        validationState.isValid = false;
        validationState.errorMessage = `Invalid file format. Accepted formats: ${acceptedImageFormats.join(', ')}.`;
        return validationState;
    }

    // Check file size
    const fileSizeMB = file.size / 1024 / 1024; // Convert bytes to MB
    if (fileSizeMB > maxImageSizeInMB) {
        validationState.isValid = false;
        validationState.errorMessage = `File size exceeds the maximum limit of ${maxImageSizeInMB} MB.`;
        return validationState;
    }

    if (fileSizeMB < minImageSizeInMB) {
        validationState.isValid = false;
        validationState.errorMessage = `File size must be at least ${minImageSizeInMB} MB.`;
        return validationState;
    }

    const { height, width } = imageData;

    if (width > maxImageWidth || height > maxImageHeight) {

        validationState.isValid = false;
        validationState.errorMessage = `Image dimensions exceed the maximum limit of ${maxImageWidth}x${maxImageHeight} pixels.`;

    } else if (width < minImageWidth || height < minImageHeight) {

        validationState.isValid = false;
        validationState.errorMessage = `Image dimensions must be at least ${minImageWidth}x${minImageHeight} pixels.`;

    }
    return validationState;
}
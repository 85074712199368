//**
// This utility use to generate the validation rules from the form fields 
//
//**/

import { ErrorMessage } from "./../../data/appMessages";


export const getValidationRules = (sections) => {
    let valRules = {};

    const processField = (field) => {
        if (field.validation && field.validation.length > 0) {
            const fieldValRules = field.validation.map(valItem => getValidationRule(field, valItem));
            valRules = {
                ...valRules,
                [field.dataField]: fieldValRules
            };
        }
    };

    const processRowGroup = (rowGroup) => {
        rowGroup.fields.forEach(processField);
    };

    sections.forEach(section => {
        if (section.rowGroup && section.rowGroup.length > 0) {
            section.rowGroup.forEach(processRowGroup);
        } else if (section.fields && section.fields.length > 0) {
            section.fields.forEach(processField);
        }
    });

    return valRules;
};


// Note: Element is not use as of now
const getValidationRule = (element, validation) => {

    let valObj = {};
    switch (validation.type) {
        case "require":
            valObj = {
                type: validation.type,
                message: ErrorMessage.FieldRequired.replace('{0}', element.Field_Name)
            }
            break;
        case "email":
            valObj = {
                type: validation.type,
                message: ErrorMessage.EmailAddressNotValid
            }
            break;
        case "password":
            valObj = {
                type: validation.type,
                message: ErrorMessage.NOTVALIDPATTERN.replace("{0}", "Password")
            }
            break;
        case 'compare':
            valObj = {
                type: validation.type,
                compareEle: validation.compareEle,
                message: ErrorMessage.CompareField.replace("{0}", element.Field_Name)
            }
            break;
        case 'validEIN':
        case 'validPhone':
        case 'validFax': {
            const fieldNames = {
                validEIN: "EIN number",
                validPhone: "phone number",
                validFax: "fax number"
            };

            valObj = {
                type: validation.type,
                message: ErrorMessage.InvalidField.replace("{0}", fieldNames[validation.type])
            };
            break;
        }
        default:
            break;
    }
    // Please enter all the case
    return valObj;

}
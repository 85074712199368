import React, { lazy, useCallback, useState } from "react";
import PropTypes from 'prop-types';

import { FormFieldTypes } from "./libs/data/formFieldType";
import { TextInputType } from "./libs/data/formControlTypes";
import { ValidateFileField } from "../../utils/Validation/Validation.js";

const Line = lazy(() => import("./ui/separator/Line"));
const FormMainTitle = lazy(() => import("./ui/formTitle/FormMainTitle"));
const FormSelectField = lazy(() => import("./formFields/FormSelectField"));
const FormInputFields = lazy(() => import("./formFields/FormInputFields.js"));
const FormCKEditorField = lazy(() => import("./formFields/FormCKEditorField"));
const FormCheckboxField = lazy(() => import("./formFields/FormCheckBoxFields"));
const FormTextAreaFields = lazy(() => import("./formFields/FormTextAreaField"));
const FormFileUploadField = lazy(() => import("./formFields/FormFileUploadField"));
const FormDatePickerField = lazy(() => import("./formFields/FormDatePickerField"));
const FormMaskInputField = lazy(() => import("./formFields/FormMaskInputField.js"));
const FormRadioButtonField = lazy(() => import("./formFields/FormRadioButtonField"));
const FormPhoneInputField = lazy(() => import("./formFields/FormPhoneInputField.js"));
const FormImageUploadField = lazy(() => import("./formFields/FormImageUploadField.js"));
const FormCustomSelectField = lazy(() => import("./formFields/FormCustomSelectField.js"));
const FormEditableSelectField = lazy(() => import("./formFields/FormEditableSelectField"));

const ComponentMap = {
  [FormFieldTypes.INPUT]: FormInputFields,
  [FormFieldTypes.PASSWORD]: FormInputFields,
  [FormFieldTypes.NUMERIC]: FormInputFields,
  [FormFieldTypes.MASKINPUT]: FormMaskInputField,
  [FormFieldTypes.TEXTAREA]: FormTextAreaFields,
  [FormFieldTypes.CHECKBOX]: FormCheckboxField,
  [FormFieldTypes.RADIOBUTTON]: FormRadioButtonField,
  [FormFieldTypes.DATEPICKER]: FormDatePickerField,
  [FormFieldTypes.SELECT]: FormSelectField,
  [FormFieldTypes.TEXTEDITOR]: FormCKEditorField,
  [FormFieldTypes.FILE]: FormFileUploadField,
  [FormFieldTypes.IMAGE]: FormImageUploadField,
  [FormFieldTypes.PHONE]: FormPhoneInputField,
  [FormFieldTypes.EDITABLEDROPDOWN]: FormEditableSelectField,
  [FormFieldTypes.CUSTOMSELECT]: FormCustomSelectField,
  [FormFieldTypes.SEPARATOR]: Line,
  [FormFieldTypes.MAINFORMTITLE]: FormMainTitle,
};

const FormFields = ({
  sections,
  formData,
  validState,
  onFormStateChange,
  onUpdateValidation,
  formSetting,
  onFormFieldChange,
  onFieldBlure,
  onActionChange,
  onFileValidation,
  fieldValiadtionRules
}) => {

  const [overRideProps, setOverRideProps] = useState({});

  const handleInputChange = (dataField, value) => {
    // Check if the new value is different from the current value
    if (formData[dataField] !== value) {
      let updatedData = { ...formData, [dataField]: value };
      updatedData = stateChangeAction(dataField, updatedData);

      // Call the onFormStateChange and onFormFieldChange callbacks if the value changed
      onFormStateChange?.(updatedData);
      onFormFieldChange?.(dataField, updatedData);
    }
  };

  const handleBlure = (dataField, updatedValue = null) => {
    let updatedData = { ...formData };

    if (updatedValue !== null) {
      updatedData = { ...formData, [dataField]: updatedValue };
      updatedData = stateChangeAction(dataField, updatedData);

      onFormStateChange?.(updatedData);
    }

    if (onUpdateValidation) {
      onUpdateValidation(dataField, updatedData);
    }
    onFieldBlure?.(dataField, updatedData);
  };


  const handleFileFieldBlur = (dataField, updatedFileValues, fieldSettings) => {
    let updatedData = { ...formData };

    if (updatedFileValues !== null) {
      updatedData = { ...formData, [dataField]: updatedFileValues };
      updatedData = stateChangeAction(dataField, updatedData);

      onFormStateChange?.(updatedData);
    }

    const fileValidState = ValidateFileField(
      updatedFileValues,
      fieldValiadtionRules[dataField],
      updatedData,
      fieldSettings.acceptedFileFormats,
      fieldSettings.minFileSizeInMB,
      fieldSettings.maxFileSizeInMB,
    );

    onFileValidation?.(dataField, fileValidState);

  }


  const stateChangeAction = (dataField, updatedState) => {

    const formField = memoizedSelectFormField(sections, dataField);

    formField?.changeAction?.resetValue?.forEach(({ dataField, value }) => {
      if (dataField && value) {
        updatedState[dataField] = value;
      }
    });

    if (formField?.changeAction?.resetFieldSetting?.length > 0) {
      let newOverRideProps = { ...overRideProps };

      formField.changeAction.resetFieldSetting.forEach(({ dependancyField: dependancyFields, condition }) => {
        const isConditionMet = condition.type === "=" && updatedState[dataField] === condition.value;

        if (Array.isArray(dependancyFields)) {
          dependancyFields.forEach(({ dataField, updateProps, resetValue }) => {
            if (isConditionMet) {
              newOverRideProps[dataField] = { ...updateProps };
              updatedState[dataField] = resetValue;
            } else {
              delete newOverRideProps[dataField];
            }
          });
        }
      });

      setOverRideProps(newOverRideProps);
    }

    return updatedState;
  };


  const memoizedSelectFormField = useCallback((sections, dataField) => {
    // Helper function to search for a field inside an array of fields
    const searchFields = (fields, dataField) => {
      return fields.find(field => field.dataField === dataField) || null;
    };

    const findFieldInRowGroup = (rowGroup, dataField) => {
      return rowGroup ? searchFields(rowGroup.fields, dataField) : null;
    };

    const findFieldInSection = (section, dataField) => {
      if (section.rowGroup && section.rowGroup.length > 0) {
        for (const rowGroup of section.rowGroup) {
          const foundField = findFieldInRowGroup(rowGroup, dataField);
          if (foundField) return foundField;
        }
      }
      return section.fields ? searchFields(section.fields, dataField) : null;
    };

    // Iterate through sections and find the field
    for (const section of sections) {
      const foundField = findFieldInSection(section, dataField);
      if (foundField) return foundField;
    }

    return null; // Return null if the field is not found
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const handleActionChange = (actionName, dataField) => {
    if (onActionChange)
      onActionChange(actionName, dataField, formData);
  }


  const renderField = (field, index) => {

    const { containerCss = "col-md-6" } = field.style || {};

    const isRequired = fieldValiadtionRules?.[field.dataField]?.length > 0;

    const commonProps = {
      keyId: `${field.dataField}_${index}`,
      dataField: field.dataField,
      labelName: field.label,
      name: field.id,
      type: fieldTypeToInputType(field.fieldType),
      value: formData?.[field.dataField] || "",
      error: validState.error[field.dataField] || "",
      formSetting,
      formData,
      changeAction: field.changeAction,
      overRideProps: overRideProps?.[field.dataField],
      isRequired,
      fieldSetting: field.fieldSetting,
      buttonConfig: field.fieldSetting?.buttonConfig,
      controlConfig: field.fieldSetting?.controlConfig,
      inputInfoConfig: field.fieldSetting?.inputInfoConfig,
      onChange: handleInputChange,
      onActionChange: handleActionChange,
      onValidation: onUpdateValidation,
      onBlure: handleBlure,
      onFileFleidBlur: handleFileFieldBlur,
    };

    const FieldComponent = ComponentMap[field.fieldType];

    if (!FieldComponent) return null;

    if (field.fieldType === FormFieldTypes.SEPARATOR) {
      return <FieldComponent containerCss={containerCss} />;
    }

    if (field.fieldType === FormFieldTypes.MAINFORMTITLE) {
      return <FieldComponent {...field.fieldSetting} containerCss={containerCss} key={field.dataField + "_" + index} />;
    }

    return (
      <div className={containerCss} key={field.dataField + "_" + index}>
        <FieldComponent {...commonProps} options={field.fieldSetting?.options} key={field.dataField + "_" + index} />
      </div>
    );
  };

  const renderRowGroups = (rowGroups) => {
    return rowGroups.map((rowGroup, index) => {
      const { groupStyle = "col-md-12" } = rowGroup.style || {}; // Move the constant declaration here
      const GroupWrapper = rowGroup.groupWrapper || React.Fragment; // Check if a groupWrapper is provided, fallback to React.Fragment
      const isJSXLiteral = React.isValidElement(GroupWrapper); // Check if it's JSX or a functional component

      return (
        <div key={`rowGroup_${index + 1}`} className={`${groupStyle}`}>
          {isJSXLiteral ? (
            React.cloneElement(GroupWrapper, {}, rowGroup.fields.map(renderField))
          ) : (
            <GroupWrapper>
              {rowGroup.fields.map(renderField)}
            </GroupWrapper>
          )}
        </div>
      );
    });
  };

  const renderSection = (section, index) => {
    const { sectionStyle = "col-md-12" } = section.style || {};
    const Wrapper = section.wrapperTemplate || React.Fragment;
    const isJSXLiteral = React.isValidElement(Wrapper);

    const hasRowGroup = Array.isArray(section.rowGroup);

    const wrapperContent = hasRowGroup
      ? renderRowGroups(section.rowGroup)
      : section.fields.map(renderField);

    // Determine the wrapper element based on isJSXLiteral
    const element = isJSXLiteral
      ? React.cloneElement(Wrapper, {}, wrapperContent)
      : <Wrapper>{wrapperContent}</Wrapper>;

    return (
      <div className={sectionStyle} key={`section_${index}`}>
        {element}
      </div>
    );

  };

  return (
    <>
      {

        sections.map(renderSection)
      }
    </>
  );
};

const fieldTypeToInputType = (fieldtype) => {
  switch (fieldtype) {
    case FormFieldTypes.PASSWORD:
      return TextInputType.PASSWORD;
    case FormFieldTypes.NUMERIC:
      return TextInputType.NUMBER;
    case FormFieldTypes.PHONE:
      return TextInputType.NUMBER;
    case FormFieldTypes.CHECKBOX:
      return TextInputType.CHECKBOX;
    case FormFieldTypes.EDITABLEDROPDOWN:
      return TextInputType.TEXT;
    case FormFieldTypes.DATEPICKER:
      return TextInputType.DATEPICKER;
    case FormFieldTypes.TEXTAREA:
      return TextInputType.TEXT;
    case FormFieldTypes.TEXTEDITOR:
      return TextInputType.TEXT;
    case FormFieldTypes.FILE:
      return TextInputType.FILE;
    case FormFieldTypes.RADIOBUTTON:
      return TextInputType.RADIO;
    default:
      return TextInputType.TEXT;
  }
};
export default FormFields;


FormFields.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      rowGroup: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              dataField: PropTypes.string.isRequired,
              fieldType: PropTypes.oneOf(Object.values(FormFieldTypes)).isRequired,
              label: PropTypes.string,
              style: PropTypes.shape({
                containerCss: PropTypes.string,
              }),
              fieldSetting: PropTypes.shape({
                options: PropTypes.array,
                buttonConfig: PropTypes.object,
                controlConfig: PropTypes.object,
                inputInfoConfig: PropTypes.object,
                resetFieldSetting: PropTypes.array,
              }),
              changeAction: PropTypes.shape({
                resetValue: PropTypes.arrayOf(
                  PropTypes.shape({
                    dataField: PropTypes.string,
                    value: PropTypes.any,
                  })
                ),
                resetFieldSetting: PropTypes.arrayOf(
                  PropTypes.shape({
                    dependancyField: PropTypes.arrayOf(
                      PropTypes.shape({
                        dataField: PropTypes.string,
                        updateProps: PropTypes.object,
                        resetValue: PropTypes.any,
                      })
                    ),
                    condition: PropTypes.shape({
                      type: PropTypes.string,
                      value: PropTypes.any,
                    }),
                  })
                ),
              }),
            })
          ),
          style: PropTypes.shape({
            groupStyle: PropTypes.string,
          }),
          groupWrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        })
      ),
      fields: PropTypes.array,
      style: PropTypes.shape({
        sectionStyle: PropTypes.string,
      }),
      wrapperTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    })
  ).isRequired,
  formData: PropTypes.object.isRequired,
  validState: PropTypes.shape({
    error: PropTypes.object,
  }).isRequired,
  onFormStateChange: PropTypes.func,
  onUpdateValidation: PropTypes.func,
  onFileValidation: PropTypes.func,
  formSetting: PropTypes.object,
  onActionChange: PropTypes.func,
  onFormFieldChange: PropTypes.func,
  onFieldBlure: PropTypes.func,
  fieldValiadtionRules: PropTypes.object,
};


import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../../utils/API/fetchBaseQuery";
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

const userAPI = createApi({
    baseQuery: customFetchBase,
    reducerPath: 'userAPI',
    endpoints: (builder) => ({

        getAllUsersList: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/GetUserList`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getUserDetailsById: builder.query({
            query: (data) => ({
                url: `/UserManagement/GetUserDetailsById?plUserKey=${data}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addUser: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/AddUser`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateUserDetails: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/UpdateUser`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteUser: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/DeleteUser?plUserKey=${data}`,
                method: 'DELETE',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        resetPassword: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/ResetPassword/`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    }),

});

export const {
    useGetAllUsersListMutation,
    useLazyGetUserDetailsByIdQuery,
    useAddUserMutation,
    useUpdateUserDetailsMutation,
    useDeleteUserMutation,
    useResetPasswordMutation
} = userAPI;

export default userAPI;
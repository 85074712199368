import { FormFieldTypes } from "../../components/finalForms/libs/data/formFieldType";
import { validationTypes } from "../../components/finalForms/libs/data/ValidationTypes";

const ResetPasswordData = {
    name: "Login From",
    initialState: { email: "", newPassword: "", confirmPassword: "" },
    section: [
        {
            sectionId: 'basicDetailSection',
            title: "Basic User Details",
            row: {},
            style: {
                sectionStyle: "col-lg-12 col-md-12",
            },
            wrapperTemplate: <div className=" row mx-1 mb-2"></div>,
            fields: [
                {
                    id: "email",
                    label: "Email Address",
                    Field_Name: "Email Address",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "email",
                    fieldSetting: {
                        placeholder: "email",
                        allowSpace: true,
                        isDisable: true
                    },
                    validation: [{ type: "require" }],
                    style: {
                        containerCss: "col-md-12 mb-2"
                    }
                },
                {
                    id: "psPassword360",
                    label: "Password",
                    Field_Name: "password",
                    fieldType: FormFieldTypes.PASSWORD,
                    dataField: "psPassword360",
                    fieldSetting: {
                        placeholder: "Please Enter Password",
                        allowSpace: true,
                        isDisable: false,
                    },
                    validation: [
                        { type: validationTypes.REQUIRE },
                        { type: validationTypes.PASSWORD },
                    ],
                    style: {
                        containerCss: "col-lg-12 col-md-12 mb-2",
                    },
                },
                {
                    id: "psConfirmPassword360",
                    label: "Confirm Password",
                    Field_Name: "confirm password",
                    fieldType: FormFieldTypes.PASSWORD,
                    dataField: "psConfirmPassword360",
                    fieldSetting: {
                        placeholder: "Please Enter Confirm Password",
                        allowSpace: true,
                        isDisable: false,
                    },
                    validation: [
                        { type: validationTypes.REQUIRE },
                        { type: validationTypes.PASSWORD },
                        { type: validationTypes.COMPARE, compareEle: 'psPassword360' }
                    ],
                    style: {
                        containerCss: "col-lg-12 col-md-12 mb-2",
                    },
                },
            ],
        }
    ]
};

export default ResetPasswordData;
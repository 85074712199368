import React from "react";
import { Navigate } from "react-router-dom";

/** Data files */
import { securityKey } from "../../utils/ENUMS/SecurityKey";

import CollapsibleGrid from "../../pages/testPage/CollapsibleGrid";
import UnderCunstruction from "../../components/ui/underCunstruction/UnderCunstruction";

const FinalMolGridPage = React.lazy(() =>
  import("../../pages/testPage/FinalMolGridPage")
);

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const WorkSpace = React.lazy(() => import("../../pages/workspace/MyWorkspace"));
const Departments = React.lazy(() =>
  import("../../pages/departments/Departments")
);
const Repairs = React.lazy(() => import("../../pages/repairs/Repairs"));

// Dashboard
const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"));
const DashboardScopes = React.lazy(() =>
  import("../../pages/dashboard/features/scopes/Scopes")
);
const DashboardTasks = React.lazy(() =>
  import("../../pages/dashboard/features/tasks/Tasks")
);
const DashboardLoaners = React.lazy(() =>
  import("../../pages/dashboard/features/loaners/Loaners")
);
const DashboardEmails = React.lazy(() =>
  import("../../pages/dashboard/features/emails/Emails")
);
const DashboardShippingStatus = React.lazy(() =>
  import("../../pages/dashboard/features/shippingStatus/ShippingStatus")
);
const DashboardInventory = React.lazy(() =>
  import("../../pages/dashboard/features/inventory/Inventory")
);
const DashboardAcquisition = React.lazy(() =>
  import("../../pages/dashboard/features/acquisitions/Acquisition")
);
const DashboardSearches = React.lazy(() =>
  import("../../pages/dashboard/features/searches/Searches")
);
const DashboardRepairMetrics = React.lazy(() =>
  import("../../pages/dashboard/features/repairMetrics/RepairMetrics")
);
const DashboardTurnAroundTimes = React.lazy(() =>
  import("../../pages/dashboard/features/turnAroundTimes/TurnAroundTimes")
);
const DashboardFlags = React.lazy(() =>
  import("../../pages/dashboard/features/flags/Flags")
);

// Clients
const Clients = React.lazy(() => import("../../pages/clients/Clients"));

// Scopes
const Scopes = React.lazy(() => import("../../pages/scopes/Scopes"));

// Inventory
const ManageInventory = React.lazy(() =>
  import("../../pages/inventory/features/inventory/ManageInventory")
);
const ManagePurchaseOrders = React.lazy(() =>
  import("../../pages/inventory/features/purchaseOrders/ManagePurchaseOrder")
);
const ManageAdjustments = React.lazy(() =>
  import("../../pages/inventory/features/adjustments/ManageAdjustments")
);

/** Invoices */
const Invoices = React.lazy(() => import("../../pages/invoices/Invoices"));

// Administration
const Security = React.lazy(() =>
  import("../../pages/administration/features/security/Security")
);
const Companies = React.lazy(() =>
  import("../../pages/administration/features/companies/Companies")
);
const Distributors = React.lazy(() =>
  import("../../pages/administration/features/distribution/Distributors")
);
const PricingLists = React.lazy(() =>
  import("../../pages/administration/features/pricingList/PricingLists")
);
const ReferenceTables = React.lazy(() =>
  import("../../pages/administration/features/referenceTables/ReferenceTables")
);
const ManageStaff = React.lazy(() =>
  import("../../pages/administration/features/manageStaff/ManageStaff")
);

// Repair Items
const RepairItems = React.lazy(() =>
  import("../../pages/repairItems/features/repairItems/ManageRepairItems")
);
const Instruments = React.lazy(() =>
  import("../../pages/repairItems/features/instruments/Instruments")
);
const ScopeModel = React.lazy(() => import("../../pages/repairItems/features/scopeModel/ManageScopeModel"));

export const ComponentNavigation = [
  {
    id: "pageNotFound",
    path: "pageNotFound",
    exact: true,
    title: "",
    component: NotFound,
    text: "Page not found",
    securityKey: "",
  },
  // {
  //     id: 'dashboard_root',
  //     path: '/',
  //     exact: true,
  //     title: 'Dashboard',
  //     component: Dashboard,
  //     hasParams: false,
  //     text: 'Dashboard',
  //     securityKey: securityKey.DASHBOARD,
  //     redirect: '/Dashboard/Scopes',
  // },
  {
    id: "dashboard",
    path: "/Dashboard",
    exact: false,
    title: "Dashboard",
    component: () => <Navigate to="/Dashboard/Scopes" />,
    hasParams: false,
    text: "Dashboard",
    securityKey: securityKey.DASHBOARD,
    redirect: "/Dashboard/Scopes",
    children: [
      {
        id: "scopes",
        path: "/Dashboard/Scopes",
        exact: true,
        title: "Scopes",
        component: DashboardScopes,
        hasParams: false,
        text: "Scopes",
        securityKey: securityKey.DASHBOARD_SCOPES,
      },
      {
        id: "tasks",
        path: "/Dashboard/Tasks",
        exact: true,
        title: "Tasks",
        component: DashboardTasks,
        hasParams: false,
        text: "Tasks",
        securityKey: securityKey.DASHBOARD_TASKS,
      },
      {
        id: "loaners",
        path: "/Dashboard/Loaners",
        exact: true,
        title: "Loaners",
        component: DashboardLoaners,
        hasParams: false,
        text: "Loaners",
        securityKey: securityKey.DASHBOARD_LOANERS,
      },
      {
        id: "emails",
        path: "/Dashboard/Emails",
        exact: true,
        title: "Emails",
        component: DashboardEmails,
        hasParams: false,
        text: "Emails",
        securityKey: securityKey.DASHBOARD_EMAILS,
      },
      {
        id: "shoppingStatus",
        path: "/Dashboard/ShoppingStatus",
        exact: true,
        title: "Shopping Status",
        component: DashboardShippingStatus,
        hasParams: false,
        text: "Shopping Status",
        securityKey: securityKey.DASHBOARD_SHIPPING_STATUS,
      },
      {
        id: "inventory",
        path: "/Dashboard/Inventory",
        exact: true,
        title: "Inventory",
        component: DashboardInventory,
        hasParams: false,
        text: "Inventory",
        securityKey: securityKey.DASHBOARD_INVENTORY,
      },
      {
        id: "acquisitions",
        path: "/Dashboard/Acquisitions",
        exact: true,
        title: "Acquisitions",
        component: DashboardAcquisition,
        hasParams: false,
        text: "Acquisitions",
        securityKey: securityKey.DASHBOARD_ACQUISITIONS,
      },
      {
        id: "search",
        path: "/Dashboard/QuickSearches",
        exact: true,
        title: "Searches",
        component: DashboardSearches,
        hasParams: false,
        text: "Searches",
        securityKey: securityKey.DASHBOARD_SEARCHES,
      },
      {
        id: "repairMetrics",
        path: "/Dashboard/RepairMetrics",
        exact: true,
        title: "Repair Metrics",
        component: DashboardRepairMetrics,
        hasParams: false,
        text: "Repair Metrics",
        securityKey: securityKey.DASHBOARD_REPAIR_METRICS,
      },
      {
        id: "repairTimes",
        path: "/Dashboard/RepairTimes",
        exact: true,
        title: "Repair Times",
        component: Dashboard,
        hasParams: false,
        text: "Repair Times",
        securityKey: securityKey.DASHBOARD_REPAIR_TIMES,
      },
      {
        id: "repairItemCosts",
        path: "/Dashboard/RepairItemCosts",
        exact: true,
        title: "Repair Item Costs",
        component: Dashboard,
        hasParams: false,
        text: "Repair Item Costs",
        securityKey: securityKey.DASHBOARD_REPAIR_ITEM_COSTS,
      },
      {
        id: "turnAroundTimes",
        path: "/Dashboard/TurnAroundTimes",
        exact: true,
        title: "Turn Around Times",
        component: DashboardTurnAroundTimes,
        hasParams: false,
        text: "Turn Around Times",
        securityKey: securityKey.DASHBOARD_TURN_AROUND_TIMES,
      },
      {
        id: "flags",
        path: "/Dashboard/Flags",
        exact: true,
        title: "Flags",
        component: DashboardFlags,
        hasParams: false,
        text: "Flags",
        securityKey: securityKey.DASHBOARD_FLAGS,
      },
      {
        id: "revExp",
        path: "/Dashboard/RevExp",
        exact: true,
        title: "Rev/Exp Distribution",
        component: Dashboard,
        hasParams: false,
        text: "Rev/Exp Distribution",
        securityKey: securityKey.DASHBOARD_REV_EXP_DISTRIBUTION,
      },
      {
        id: "points",
        path: "/Dashboard/Points",
        exact: true,
        title: "Points",
        component: Dashboard,
        hasParams: false,
        text: "Points",
        securityKey: securityKey.DASHBOARD_POINTS,
      },
      {
        id: "techHours",
        path: "/Dashboard/TechHours",
        exact: true,
        title: "Tech Hours",
        component: Dashboard,
        hasParams: false,
        text: "Tech Hours",
        securityKey: securityKey.DASHBOARD_TECH_HOURS,
      },
      {
        id: "gpIntegration",
        path: "/Dashboard/GPIntegration",
        exact: true,
        title: "GP Integration",
        component: Dashboard,
        hasParams: false,
        text: "GP Integration",
        securityKey: securityKey.DASHBOARD_GP_INTEGRATION,
      },
      {
        id: "developmentList",
        path: "/Dashboard/DevelopmentList",
        exact: true,
        title: "Development List",
        component: Dashboard,
        hasParams: false,
        text: "Development List",
        securityKey: securityKey.DASHBOARD_DEVELOPMENT_LIST,
      },
      {
        id: "colorPalette",
        path: "/Dashboard/ColorPalette",
        exact: true,
        title: "Color Palette",
        component: Dashboard,
        hasParams: false,
        text: "Color Palette",
        securityKey: securityKey.DASHBOARD_COLOR_PALETTE,
      },
      {
        id: "points",
        path: "/Dashboard/Points",
        exact: true,
        title: "Points",
        component: Dashboard,
        hasParams: false,
        text: "Points",
        securityKey: securityKey.DASHBOARD_STEVE,
      },
    ],
  },
  {
    id: "administration",
    path: "/Administration",
    exact: true,
    title: "Administration",
    component: () => <Navigate to="/Administration/Security" />,
    // component: Administration,
    hasParams: false,
    text: "Administration",
    securityKey: securityKey.ADMINISTRATION,
    redirect: "/Administration/Security",
    children: [
      {
        id: "security",
        path: "/Administration/Security",
        exact: true,
        title: "Security",
        component: Security,
        hasParams: false,
        text: "Security",
        securityKey: securityKey.ADMINISTRATION_SECURITY,
      },
      {
        id: "companies",
        path: "/Administration/Companies",
        exact: true,
        title: "Companies",
        component: Companies,
        hasParams: false,
        text: "Companies",
        securityKey: securityKey.ADMINISTRATION_COMPANIES,
      },
      {
        id: "distributors",
        path: "/Administration/Distributors",
        exact: true,
        title: "Distributors",
        component: Distributors,
        hasParams: false,
        text: "Distributors",
        securityKey: securityKey.ADMINISTRATION_DISTRIBUTORS,
      },
      {
        id: "pricingLists",
        path: "/Administration/PricingLists",
        exact: true,
        title: "Pricing Lists",
        component: PricingLists,
        hasParams: false,
        text: "Pricing Lists",
        securityKey: securityKey.ADMINISTRATION_PRICING_LISTS,
      },
      {
        id: "referenceTables",
        path: "/Administration/ReferenceTables",
        exact: true,
        title: "Reference Tables",
        component: ReferenceTables,
        hasParams: false,
        text: "Reference Tables",
        securityKey: securityKey.ADMINISTRATION_REFERENCE_TABLES,
      },
      {
        id: "manageStaff",
        path: "/Administration/ManageStaff",
        exact: true,
        title: "Manage Staff",
        component: ManageStaff,
        hasParams: false,
        text: "Manage Staff",
        securityKey: securityKey.ADMINISTRATION_MANAGE_STAFF,
      },
    ],
  },
  {
    id: "MyWorkspace",
    path: "/MyWorkspace",
    exact: true,
    title: "My Workspace",
    component: WorkSpace,
    hasParams: false,
    text: "My Workspace",
    securityKey: securityKey.MY_WORKSPACE,
    children: [
      {
        id: "workspace",
        path: "/MyWorkspace/Projects",
        exact: true,
        title: "My Workspace",
        component: WorkSpace,
        hasParams: false,
        text: "Administration",
        securityKey: securityKey.MY_WORKSPACE,
      },
    ],
  },
  {
    id: "clients",
    path: "/Clients",
    exact: true,
    title: "Clients",
    component: Clients,
    hasParams: false,
    text: "Clients",
    securityKey: securityKey.CLIENTS,
  },
  {
    id: "scopes",
    path: "/Scopes",
    exact: true,
    title: "Scopes",
    // component: Departments,
    component: () => <Navigate to="/Scopes/Scopes" />,
    hasParams: false,
    text: "Scopes",
    securityKey: securityKey.SCOPES,
    children: [
      {
        id: "scopeTypes",
        path: "/Scopes/Scopes",
        exact: true,
        title: "Scope Types",
        component: Scopes,
        hasParams: false,
        text: "Scope Types",
        securityKey: securityKey.SCOPES_SCOPE_TYPES,
      },
      {
        id: "scopeTypes",
        path: "/Scopes/ScopeTypes",
        exact: true,
        title: "Scope Types",
        component: Departments,
        hasParams: false,
        text: "Scope Types",
        securityKey: securityKey.SCOPES_SCOPE_TYPES,
      },
    ],
  },
  {
    id: "repairItems",
    path: "/RepairItems",
    exact: true,
    title: "Repair Items",
    component: () => <Navigate to="/RepairItems/RepairItems" />,
    hasParams: false,
    text: "Repair Items",
    securityKey: securityKey.REPAIR_ITEMS,
    redirect: "/RepairItems/RepairItems",
    children: [
      {
        id: "repairItems",
        path: "/RepairItems/RepairItems",
        exact: true,
        title: "RepairItems",
        component: RepairItems,
        hasParams: false,
        text: "RepairItems",
        securityKey: securityKey.REPAIR_ITEMS_SUB,
      },
      {
        id: "instruments",
        path: "/RepairItems/Instruments",
        exact: true,
        title: "Instruments",
        component: Instruments,
        hasParams: false,
        text: "Instruments",
        securityKey: securityKey.REPAIR_ITEMS_INSTRUMENTS,
      },
      {
        id: "scopeModel",
        path: "/RepairItems/ScopeModel",
        exact: true,
        title: "Scope Model",
        // component: UnderCunstruction,
        component: ScopeModel,
        hasParams: false,
        text: "ScopeModel",
        securityKey: securityKey.REPAIR_ITEMS_INSTRUMENTS,
      },
    ],
  },
  {
    id: "inventory",
    path: "/Inventory",
    exact: true,
    title: "Inventory",
    // component: Inventory,
    component: () => <Navigate to="/Inventory/Inventory" />,
    hasParams: false,
    text: "Inventory",
    securityKey: securityKey.INVENTORY,
    children: [
      {
        id: "inventory",
        path: "/Inventory/Inventory",
        exact: true,
        title: "Inventory",
        // component: NotFound,
        component: ManageInventory,
        hasParams: false,
        text: "Inventory",
        securityKey: securityKey.INVENTORY,
      },
      {
        id: "purchaseOrders",
        path: "/Inventory/PurchaseOrders",
        exact: true,
        title: "Purchase Orders",
        component: ManagePurchaseOrders,
        hasParams: false,
        text: "Purchase Orders",
        securityKey: securityKey.INVENTORY_PURCHASE_ORDERS,
      },
      {
        id: "adjustments",
        path: "/Inventory/InventoryAdjustments",
        exact: true,
        title: "Inventory Adjusments",
        component: ManageAdjustments,
        hasParams: false,
        text: "Inventory Adjusments",
        securityKey: securityKey.INVENTORY_INVENTORY_ADJUSTMENTS,
      },
      {
        id: "lotNumbers",
        path: "/Inventory/LotNumberMaintenance",
        exact: true,
        title: "Lot Number Maintenance",
        component: NotFound,
        hasParams: false,
        text: "Lot Number Maintenance",
        securityKey: securityKey.INVENTORY_LOT_NUMBER_MAINTENANCE,
      },
      {
        id: "lonarScopes",
        path: "/Inventory/LonarScopes",
        exact: true,
        title: "Lonar Scopes",
        component: NotFound,
        hasParams: false,
        text: "Lonar Scopes",
        securityKey: securityKey.INVENTORY_LOANER_SCOPES,
      },
      {
        id: "suppliers",
        path: "/Inventory/Suppliers",
        exact: true,
        title: "Suppliers",
        component: NotFound,
        hasParams: false,
        text: "Suppliers",
        securityKey: securityKey.INVENTORY_SUPPLIERS,
      },
    ],
  },
  {
    id: "Acquisitions",
    path: "/Acquisitions",
    exact: true,
    title: "Acquisitions",
    component: Dashboard,
    hasParams: false,
    text: "Acquisitions",
    securityKey: securityKey.ACQUISITIONS,
    children: [
      {
        id: "supplierPO",
        path: "/Acquisitions/SupplierPO",
        exact: true,
        title: "Supplier POs",
        component: Departments,
        hasParams: false,
        text: "Supplier POs",
        securityKey: securityKey.SUPPLIER_POS,
      },
      {
        id: "receiveReturn",
        path: "/Acquisitions/ReceiveReturn",
        exact: true,
        title: "Receive/Returns",
        component: Departments,
        hasParams: false,
        text: "Receive/Returns",
        securityKey: securityKey.RECEIVE_RETURN_ACQUISITIONS,
      },
    ],
  },
  {
    id: "repairs",
    path: "/Repairs",
    exact: true,
    title: "Repairs",
    component: Repairs,
    hasParams: false,
    text: "Repairs",
    securityKey: securityKey.REPAIRS,
  },
  {
    id: "departments",
    path: "/Departments",
    exact: true,
    title: "Departments",
    component: Departments,
    hasParams: false,
    text: "Departments",
    securityKey: securityKey.DEPARTMENTS,
  },
  {
    id: "Invoices",
    path: "/Invoices",
    exact: true,
    title: "Invoices",
    component: Invoices,
    hasParams: false,
    text: "Invoices",
    securityKey: securityKey.INVOICES,
  },
  // {
  //     id: 'ResetPassword',
  //     path: '/ResetPassword',
  //     exact: true,
  //     title: 'Reset Password',
  //     component: ResetPassword,
  //     hasParams: false,
  //     text: 'Reset Password',
  //     securityKey: '',
  // },

  {
    id: "CollapsibleGridId",
    path: "/CollapsibleGrid",
    exact: true,
    title: "CollapsibleGrid",
    component: CollapsibleGrid,
    hasParams: false,
    text: "CollapsibleGrid",
  },

  {
    id: "FinalMolGridPage",
    path: "/FinalMolGridPage",
    exact: true,
    title: "FinalMolGridPage",
    component: FinalMolGridPage,
    hasParams: false,
    securityKey: securityKey.MY_WORKSPACE,
    text: "FinalMolGridPage",
  },
];
